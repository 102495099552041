@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/typography';

.root {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &::before {
        content: '🙌';
        position: absolute;
        left: 0;
        font-size: 3rem;
        margin-right: 1rem;
        opacity: 0;
        transform: translateX(100%);
        pointer-events: none;
        transition-property: transform, opacity;
    }
}

.section {
    display: flex;
    width: 100%;
    transition: transform $fast;
    justify-content: space-between;
    animation: fadeIn $slow $ease;
}

.secondaryFields {
    animation: fadeIn $slow;
}

.invalid .secondaryFields {
    display: none;
}

.input {
    height: 4rem;
    width: 100%;
    background-color: transparent;
    color: $white;
    border: 0;
    border-bottom: 1px solid $white;

    @include labelMedium;

    font-size: 1.4rem; // Prevent iOS from auto zooming into input
}

.firstName {
    margin-right: 2rem;
}

.submitting {
    @keyframes wiggle {
        0%,
        100% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-2px);
        }
    }

    .submitButton:disabled {
        opacity: 1;
        animation: wiggle $fast $ease infinite;
    }

    .submitIcon {
        transform: rotate(-45deg);
        color: $gold;
    }
}

.submitted {
    flex-direction: row;

    &::before {
        transform: translateX(0);
        opacity: 1;
    }

    .primaryFields {
        width: calc(100% - 4rem);
        transform: translateX(4rem);
    }

    .secondaryFields,
    .primaryFields:last-child {
        display: none;
    }
}

.submit {
    padding-top: 2.2rem;
    align-items: center;
    justify-content: right;
}

.submitButton {
    background: none;
    border: none;
    color: $gold;
    display: flex;
    margin-left: 0.1rem;
}

.submitIcon {
    width: 2rem;
    height: 1.1rem;
    margin-left: 1rem;
    transition-property: transform, opacity;
    transition-duration: $slow;
    transition-timing-function: $easeOutCubic;
}

@include medium {
    .input {
        font-size: 1.6rem;
    }

    .submitButton {
        transition:
            color $fast $easeInOutQuad,
            transform $fast $easeInOutQuad;

        &:hover:not(:disabled) {
            color: $gold;
            transform: translateX(0.5rem);
        }
    }

    .submitIcon {
        width: 3.05rem;
        height: 1.733rem;
    }
}
