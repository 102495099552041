@import 'styles/animations';
@import 'styles/colors';

.root {
    float: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 3.6rem;
    height: 3.6rem;
    padding: 0.4rem 0;

    span {
        display: block;
        width: 2.4rem;
        height: 0.3rem;
        background-color: $pink;
        transition: all $fast $easeInOutQuad;

        &:nth-child(2) {
            background-color: $blue;
        }
    }
}

.isMenuOpen {
    span {
        &:nth-child(1) {
            transform: translate(0, 6px) rotate(45deg);
        }

        &:nth-child(2) {
            transform: translate(0, -5px) rotate(-45deg);
        }
    }
}
