@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/fonts';

// Global styles

html {
    -webkit-tap-highlight-color: transparent;
    background-color: $white;
    box-sizing: border-box;
    color: $black;
    font-family: $avantGarde;
    font-size: 62.5%;
    touch-action: manipulation;
}

body {
    overflow-x: hidden;
}

.main {
    min-height: 100vh;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    font-family: $avantGarde;
    font-weight: normal;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

p,
figure {
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

button {
    cursor: pointer;
    background: none;
    margin: 0;
    border: 0;
    padding: 0;
    border-radius: none;
    font-family: inherit;

    &:disabled {
        opacity: 0.4;
        cursor: default;
    }

    &:focus {
        outline: none;
    }
}

img {
    max-width: 100%;
    height: auto;
}

input {
    border-radius: 0;

    &[type='text'],
    &[type='email'] {
        appearance: none;
        text-transform: none;
    }

    &::placeholder {
        color: $white;
    }

    &:focus {
        outline: none;

        &::placeholder {
            opacity: 0.5;
        }
    }
}

// Prevent iOS autozoom on form elements
// Input font size cannot be less than 16px
input,
select {
    font-size: 1.6rem;
}

hr {
    border: none;
    height: 1px;
    color: $gray;
    background-color: $gray;
}

.sr-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.text-link {
    text-decoration: underline;
    color: $pink;
}
