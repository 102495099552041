@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/layout';

.root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding-top: 2rem;
    padding-bottom: 2rem;
    align-items: center;
    z-index: var(--z-header);
    transform: translateY(0);
    transition: transform $baseDuration $easeInOutQuad;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        background-color: darken($beigeDark, 5%);
        transition: opacity $superSlow $easeInOutQuad;
    }

    &.isHidden {
        transform: translateY(-100%);
    }

    &.hasFill {
        &:not(.isHidden) {
            transform: translateY(-12%);
        }

        &::before {
            height: calc(88%);
            opacity: 1;
        }
    }
}

.gridContainer {
    @include grid;
}

.logoContainer {
    grid-column: 1 / span 1;
    width: 8.6rem;
    display: grid;
    place-items: center;
    z-index: 1;

    @media (hover: hover) {
        a:hover {
            color: $pink;
        }
    }

    a:active {
        color: $pink;
    }
}

.menuContainer {
    grid-column: 4;
    z-index: 1;
}

@include small {
    .logoContainer {
        grid-column: 1 / span 2;
        width: 13.6rem;
    }

    .menuContainer {
        grid-column: 8;
    }

    .navContainer {
        display: block;
        grid-column: 3 / -1;
    }
}

@include medium {
    .root {
        margin-top: 0;
        align-items: end;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .logoContainer {
        grid-column: 1 / span 2;
        width: 16rem;
    }

    .menuContainer {
        display: none;
    }

    .navContainer {
        display: block;
        grid-column: 3 / -1;
    }
}
