@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/layout';

.root {
    @include grid;

    background: $black;
    color: $white;

    a {
        text-decoration: none;
        text-transform: uppercase;
        transition: color $fast $easeInOutQuad;

        &:not([href]) {
            pointer-events: none;
        }

        @media (hover: hover) {
            &:hover {
                color: $blue;
            }
        }
    }
}

.footerContainer {
    border-top: 0.2rem solid $pink;
}

.subHeading {
    color: $pink;
    text-transform: uppercase;
    padding-bottom: 1rem;
}

.subHeadingNewsletter {
    color: $grayDark;
    text-transform: uppercase;
    padding-bottom: 1rem;
}

.contactContainer,
.locationsContainer {
    grid-column: 1 / -1;
    padding-bottom: 3rem;
}

.contactContainer {
    a {
        text-transform: none;
    }
}

.contactSubject {
    color: $grayDarker;
    text-transform: uppercase;
}

.newsLetterContainer {
    grid-column: 1 / -1;
    margin-bottom: 3rem;
}

.newsLetterForm {
    margin-top: 1rem;
    padding: 2rem;
    background-color: #1d1d1d;
    border-radius: 0.4rem;
}

.followContainer {
    grid-column: 1/-1;
    padding-bottom: 2rem;
}

.social {
    color: $white;
    width: 2.2rem;
    grid-column: 1 / -1;
    display: inline;

    li {
        display: inline-block;
        width: 2rem;
        margin-right: 2.5rem;
    }
}

.copyrightNotice {
    grid-column: 1 / -1;
    padding-bottom: 5rem;
    margin: 0;
    max-width: max-content;

    strong {
        color: $pink;
    }
}

.tagline {
    position: relative;
    color: $pink;
    text-transform: uppercase;
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
        padding-top: 16rem;
        padding-bottom: 9.5rem;
        background-image: url('/images/footer-raccoon.webp');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24.7rem;
        display: inline-block;

        :first-child {
            color: $white;
            padding-right: 1.5rem;
        }
    }

    h2 {
        z-index: 2;
        position: relative;
    }
}

@include small {
    .tagline {
        grid-column: 2 / span 6;
    }

    .contactContainer {
        grid-column: 2 / span 3;
    }

    .locationsContainer {
        grid-column: 6 / span 2;
    }

    .newsLetterContainer {
        grid-column: 2 / span 6;
    }

    .followContainer {
        grid-column: 2/-1;
    }

    .copyrightNotice {
        grid-column: 2/-1;
    }
}

@include medium {
    .footerContainer {
        max-width: 100%;
        background-color: $black;
        padding-bottom: 5rem;
    }

    .tagline {
        grid-column: 1 / -1;
        margin: 3rem 0 4rem;

        div {
            display: flex;
        }
    }

    .contactContainer {
        grid-column: 2 / span 3;
    }

    .locationsContainer {
        grid-column: 5 / span 2;
    }

    .newsLetterContainer {
        grid-column: 8 / -2;
        margin-bottom: 8rem;
    }

    .followContainer {
        grid-column: 2 / span 2;
        padding: 0;
    }

    .copyrightNotice {
        grid-column: -6 / -2;
        justify-self: end;
        align-self: flex-end;
        padding: 0;
    }
}

@include large {
    .footerContainer {
        padding-bottom: 10rem;
    }
}
