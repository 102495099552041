@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/typography';

@include baseOnly {
    .listItem {
        font-size: clamp(4rem, 12vw, 5.2rem);
    }
}

.root {
    text-decoration: none;
    transition: color $fast $easeInOutQuad;
    color: inherit;
    max-width: 30rem;

    @media (hover: hover) {
        &:hover {
            color: $black;
        }
    }

    &.active,
    &:active {
        color: $black;
    }
}

.secondary {
    position: relative;
    width: 100%;
    font-size: 3.4rem;
    line-height: 2;
    display: inline-block;
    margin: 3.4rem 0;

    &::after {
        content: '';
        position: absolute;
        top: 0.4rem;
        left: 0;
        width: 100%;
        border-top: 0.1rem solid $white;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0.4rem;
        left: 0;
        width: 100%;
        border-bottom: 0.1rem solid $white;
    }
}

@include medium {
    .root {
        position: relative;
        display: block;
        padding: 2rem 0;

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 0.2rem;
            background-color: $pink;
            left: 0;
            bottom: 1.2rem;
            transform: scaleX(0);
            transform-origin: center left;
            transition: transform $fast $easeInOutQuad;
        }

        &::after {
            background-color: $blue;
            bottom: 0.7rem;
            transition-delay: 0.1s;
        }

        @media (hover: hover) {
            &:hover {
                color: $pink;

                &::before,
                &::after {
                    transform: scaleX(1);
                }
            }
        }

        &.active,
        &:active {
            color: $pink;

            &::before,
            &::after {
                transform: scaleX(1);
            }
        }
    }

    .secondary {
        font-size: inherit;
        margin: 0;

        &::before,
        &::after {
            border: 0;
        }

        &::after {
            top: unset;
            bottom: 0.7rem;
        }
    }
}
