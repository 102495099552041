@import 'styles/animations';
@import 'styles/colors';
@import 'styles/fonts';

// -------------------
// OneTrust
// -------------------

// Match OneTrust's breakpoints

$ot-small: 897px;
$ot-medium: 1024px;
$ot-large: 1280px;

@mixin ot-small {
    @media (min-width: $ot-small) {
        @content;
    }
}

@mixin ot-medium {
    @media (min-width: $ot-medium) {
        @content;
    }
}

@mixin ot-large {
    @media (min-width: $ot-large) {
        @content;
    }
}

// Define the top-level ID to increase selector specificity
// and avoid having to add !important to every value

#onetrust-consent-sdk#onetrust-consent-sdk {
    // --------------
    // Dialog
    // --------------

    #onetrust-pc-sdk {
        font-family: $avantGarde;
        line-height: 1.2;
        letter-spacing: 0;
        font-size: 1.2rem;

        button {
            text-transform: uppercase;
            font-size: 1.2rem;
        }

        .ot-pc-footer {
            button {
                max-width: 30rem;
                margin: 2rem auto;
            }
        }

        .ot-btn-container {
            text-align: center;
        }
    }

    #ot-pc-content {
        width: calc(100% - var(--grid-margin) - 2rem);
        margin-left: var(--grid-margin);
        padding-right: 1.8rem;
    }

    @include ot-large {
        #onetrust-pc-sdk {
            font-size: 1.4rem;
        }

        #ot-pc-content {
            width: calc(100% - 5rem);
            margin-left: 3rem;
        }
    }

    // --------------
    // Banner
    // --------------

    #onetrust-banner-sdk {
        padding: 3rem var(--grid-margin);

        &:focus {
            outline: 0;
        }

        p,
        a,
        button {
            font-family: $avantGarde;
            line-height: 1.2;
            letter-spacing: 0;
            text-transform: uppercase;
            font-size: 1rem;

            &:focus {
                outline: 0;
            }
        }

        .ot-sdk-container {
            padding: 0;
        }

        @include ot-small {
            .ot-sdk-row {
                display: flex;
                justify-content: space-between;
            }
        }

        @include ot-large {
            p,
            a,
            button {
                font-size: 1.4rem;
            }
        }
    }

    #onetrust-policy {
        margin: 0;

        @include ot-medium {
            p {
                margin: 0;
            }
        }
    }

    #onetrust-button-group {
        display: flex;
        width: 100%;
        justify-content: flex-start;

        button {
            margin: 0;
            border: 0.2rem solid $white;
            color: $white;
            text-decoration: none;
            opacity: 1;
            transition: all $fast $easeInOutQuad;

            &:first-child {
                margin-right: 1rem;
            }

            @include ot-medium {
                height: 6rem;
                margin: 0;
            }

            @media (hover: hover) {
                &:hover {
                    background: $white;
                    color: $gold;
                    opacity: 1;
                }
            }
        }
    }

    #onetrust-button-group-parent {
        position: initial;
        margin: 1rem 0 0;
        padding: 0;
        transform: none;
    }

    @include ot-small {
        #onetrust-button-group-parent {
            margin-top: 0;
        }
    }

    @include ot-medium {
        #onetrust-banner-sdk {
            .ot-sdk-row {
                width: 100%;
                display: flex;
                align-items: flex-start;
                max-width: 144rem;
                margin-right: auto;
                margin-left: auto;
            }
        }

        #onetrust-group-container {
            width: 75%;
        }

        #onetrust-button-group {
            margin: 0;
            align-items: flex-end;
            justify-content: center;
        }

        #onetrust-button-group-parent {
            width: 30%;
            margin-left: 6rem;
            padding: 0;
        }
    }
}
