@import 'styles/breakpoints';
@import 'styles/colors';

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    z-index: var(--z-godmode);
    opacity: 0.8;
    pointer-events: none;
    background-color: $pink;
}

@include medium {
    .loader {
        background-color: $gold;
    }
}
