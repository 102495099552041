@import 'styles/breakpoints';

:root {
    --block-padding: 6rem 0;
    --block-padding-top: 6rem;
    --component-margin-bottom: 5rem;
    --cta-margin-top: 4rem;
    --max-width: 144rem;

    @include medium {
        --cta-margin-top: 6rem;
        --block-padding-top: 10rem;
        --block-padding: 10rem 0;
        --component-margin-bottom: 10rem;
    }

    @include max {
        --block-padding-top: 15rem;
        --block-padding: 15rem 0;
    }
}
