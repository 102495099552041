@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    &.isMenuOpen {
        color: $white;
    }
}

.littleGuy {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.6rem;

    svg {
        width: 100%;
        height: 100%;
    }
}

.link {
    display: flex;
    width: 100%;
    height: 100%;

    color: currentcolor;
    transition: all $fast $easeInOutQuad;

    @media (hover: hover) {
        &:hover {
            color: $pink;
        }
    }
}

@include large {
    .littleGuy {
        width: 3.6rem;
        height: 3.6rem;
        margin-right: 1rem;
    }
}
