@import 'styles/fonts';
@import 'styles/typography';
@import 'styles/breakpoints';

.heroTitle {
    @include displaySmall;

    font-size: 7.6rem;
    letter-spacing: -0.04em;

    @include small {
        font-size: 12rem;
    }

    @include medium {
        font-size: 18rem;
    }

    @include large {
        font-size: 25rem;
    }
}

.heroHeadline {
    @include displaySmall;

    font-size: 5.2rem;
    letter-spacing: -0.02em;

    @include small {
        font-size: 6.4rem;
        letter-spacing: -0.04em;
    }

    @include medium {
        font-size: 8rem;
    }

    @include large {
        font-size: 12rem;
    }
}

.contactHeadline {
    @include displaySmall;

    font-size: 4.8rem;
    letter-spacing: -0.02em;

    @include medium {
        font-size: 6.4rem;
    }
}

.label {
    @include labelSmall;

    @include medium {
        @include labelLarge;
    }
}

.sectionHeadline {
    @include headingLarge;

    @include small {
        @include displaySmall;
    }

    @include large {
        @include displayLarge;
    }
}

.sectionHeadlineHero {
    @include headingMedium;

    @include small {
        @include displaySmall;
    }

    @include large {
        @include displayLarge;
    }
}

.bodyCopy {
    @include bodySmall;

    @include medium {
        @include bodyLarge;
    }
}

.buttonLabel {
    @include labelSmall;

    @include small {
        @include labelLarge;
    }
}

.headerNavItem {
    @include displaySmall;

    @include small {
        @include displayMedium;
    }

    @include medium {
        @include labelMedium;
    }
}

.headerNavUserBar {
    @include displaySmall;

    @include medium {
        @include labelMedium;
    }
}

.footerCallout {
    font-family: $avantGarde;
    font-size: 4.2rem;
    line-height: 1.1;
    letter-spacing: -0.02em;

    @include large {
        font-size: 7.5rem;
    }
}

.footerNavItem {
    @include labelMedium;
}

.thumbTitle {
    @include headingXSmall;

    @include medium {
        @include headingMedium;
    }
}

.carouselHeading {
    @include displaySmall;
}

.newsOrigin {
    @include headingSmall;

    @include medium {
        @include headingLarge;
    }
}

.newsDate {
    @include captionSmall;

    @include medium {
        @include bodyMedium;
    }

    @include large {
        @include bodyLarge;
    }
}

.aboutCapabilityTitle {
    @include headingSmall;

    @include large {
        @include headingMedium;
    }
}

.aboutCapabilityNumber {
    @include labelMedium;

    @include medium {
        font-size: 14.4rem;
    }
}

.cultureGardenSection {
    @include headingLarge;

    font-family: $nanum;

    @include medium {
        @include displayLarge;

        font-family: $nanum;
    }
}

.cultureBenefitsTitle {
    font-family: $avantGarde;
    font-size: 1.8rem;
    line-height: 1.4;
    letter-spacing: -0.02em;

    @include medium {
        @include headingMedium;
    }
}

.tableItem {
    font-family: $avantGarde;
    font-size: 1.8rem;
    line-height: 1.2;
    letter-spacing: -0.02em;

    @include large {
        @include headingSmall;
    }
}

.tableLabel {
    @include labelMedium;

    @include large {
        @include labelLarge;
    }
}

.aboutAwardRecent {
    @include headingXSmall;

    @include large {
        @include headingSmall;
    }
}

.aboutAwardCardTitle {
    @include labelMedium;

    @include medium {
        @include headingLarge;
    }
}

.aboutAwardCardTitleBody {
    @include captionSmall;

    @include medium {
        @include bodyLarge;
    }
}

.aboutAwardListBody {
    @include labelSmall;
}

.aboutAwardListHeading {
    @include headingXSmall;
}

.officeAddress {
    @include labelSmall;
}

.workFeaturedTitle {
    @include labelMedium;

    @include large {
        @include labelLarge;
    }
}

.caseStudyOverviewHeading {
    @include headingMedium;

    @include large {
        @include displaySmall;
    }
}

.caseStudyHeroTitle {
    @include displaySmall;

    font-size: 4rem;

    @include large {
        @include displayLarge;
    }
}

.featuredCaseStudyHeroTitle {
    @include displaySmall;

    font-size: 4rem;

    @include medium {
        @include displayLarge;
    }

    @include large {
        @include displayXLarge;
    }
}

.extendedWorkCapabilitiesTitle {
    @include headingSmall;
}

.extendedWorkCapabilitiesBody {
    @include bodySmall;
}

.extendedWorkResultsHeadline {
    @include headingSmall;

    @include medium {
        @include headingMedium;
    }

    @include large {
        @include headingLarge;
    }
}

.extendedWorkResultsLabel {
    @include labelLarge;
}

.extendedWorkResultsValue {
    @include displaySmall;

    font-size: 6rem;

    @include medium {
        @include displayXLarge;
    }
}

.extendedWorkImageGridBodyCopy {
    @include bodySmall;

    @include small {
        @include bodyMedium;
    }

    @include medium {
        @include bodyLarge;

        font-size: 3rem;
    }
}

.privacyPolicyHeadline {
    @include headingLarge;

    @include medium {
        @include displaySmall;
    }
}
