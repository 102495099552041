@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/typography';

.root {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 100vw;
    background-color: $gold;
    overflow-y: auto;
    transform: translateX(0);
    transition: transform $slow $easeOutExpo;
    color: $white;

    &.isMenuOpen {
        transform: translateX(-100vw);
    }
}

.navItemIcon {
    position: relative;
    display: block;
    width: 2rem;
    color: $pink;
    margin: 0.4rem 0 0 1.2rem;
}

.links {
    margin: 0 var(--grid-margin);
    padding: 0;
    padding-top: 12rem;
    list-style: none;
}

.social {
    margin: 6rem var(--grid-margin) 0;
    color: $white;

    li {
        display: inline-block;
        width: 2rem;
        margin-right: 2.5rem;

        &:last-child {
            margin: 0;
        }
    }
}

@include small {
    .social {
        margin-left: var(--grid-margin);

        li {
            width: 3.2rem;
        }
    }
}

@include medium {
    .root {
        position: relative;
        left: 0;
        width: auto;
        height: auto;
        background-color: transparent;
        transform: none;
        transition-duration: $fast;
        transition-timing-function: $easeInOutQuad;
        color: $black;

        li {
            display: inline-block;
            margin-left: 4rem;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .navItemIcon {
        display: none;
    }

    .links {
        text-align: right;
        margin-right: 0;
        margin-left: 0;
        padding: 0;
    }

    .social {
        display: none;
    }
}
