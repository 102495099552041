@import 'styles/breakpoints';

:root {
    --grid-template-columns: repeat(4, 1fr);
    --grid-margin: 35px;
    --grid-gap: 30px;
    --grid-columns: 4;
    --container-max-width: auto;

    .container {
        display: grid;
        padding-left: var(--grid-margin);
        padding-right: var(--grid-margin);
        grid-gap: 0 var(--grid-gap);
        grid-template-columns: var(--grid-template-columns);
        grid-template-rows: auto;
        max-width: var(--container-max-width);
        margin: 0 auto;
    }

    @include small {
        --grid-template-columns: repeat(8, 1fr);
        --grid-margin: 78px;
        --grid-gap: 14px;
        --grid-columns: 8;
    }

    @include medium {
        --grid-template-columns: repeat(12, 1fr);
        --grid-margin: 112px;
        --grid-gap: 12px;
        --grid-columns: 12;
    }

    @include large {
        --grid-template-columns: repeat(12, 1fr);
        --grid-margin: 100px;
        --grid-gap: 20px;
        --grid-columns: 12;
        --container-max-width: 1440px;
    }
}
