@import 'styles/animations';

.root {
    display: flex;
    align-items: center;
    padding: 2.2rem 0;
    user-select: none;
}

.input {
    margin-right: 1rem;

    + .label {
        opacity: 0.5;
        transition: opacity $fast;
        cursor: pointer;
        text-transform: unset;
    }

    &:checked + .label {
        opacity: 1;
    }
}
